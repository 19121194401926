<template>
  <div class="manageBox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="服务对象类别" name="1">
        <myTableService v-if="activeName == 1" :activeType="activeName"></myTableService>
      </el-tab-pane>
      <el-tab-pane label="补贴审批流程" name="2">
        <myTableProcess v-if="activeName == 2" :activeType="activeName"></myTableProcess>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import myTableService from "./components/myTableService";
  import myTableProcess from "./components/myTableProcess";
  export default {
    components: {
      myTableService,
      myTableProcess
    },
    data() {
      return {
        activeName: "1",
        total: 0,
        pageNum: 1,
        pageSize: 10,
        loading: false,
        tableData: [{
          phone: 1
        }],
        queryParams: {
          date: ''
        }
      }
    },
    methods: {
      /*tab切换*/
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .manageBox {
    // margin: 20px;
    background: #fff;
    padding: 20px;
    min-height: calc(100vh - 130px);
  }
</style>
